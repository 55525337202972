import styled from 'styled-components';

import { PictureContainer } from '@belong/ui-components';
import { media } from '@belong/themes';

export const Image = styled(PictureContainer)`
  display: block;
  width: 23rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${media('md')`
    width: 34.5rem;
  `};
`;
