import { Section, RichText, TEXT_NODES, Stack, getResponsiveSrc } from '@belong/ui-components';
import { ERROR_PAGE_TEST_IDS } from '@belong/ui-components/components/global/ErrorPage';
import { ISectionPageError } from './model';
import * as Styles from './styles';

const SectionPageError: React.FC<ISectionPageError> = ({ id, identifier, image, content }: ISectionPageError) => {
  const responsiveSrc = getResponsiveSrc(image, {
    sm: { w: 230 },
    md: { w: 345 }
  });

  return (
    <Section
      data-contentid={id}
      data-identifier={identifier}
      data-testid={identifier === '404-section' ? ERROR_PAGE_TEST_IDS[404] : 'section-page-error'}
      hasPaddingTop
      hasPaddingBottom
    >
      <Stack space={{ xs: 'large', md: 'xlarge' }}>
        <Styles.Image src={responsiveSrc} alt={image.alt} contentType={image.contentType} />
        <RichText html={content} h2={TEXT_NODES.headingLarge} alignment="center" />
      </Stack>
    </Section>
  );
};

export default SectionPageError;
